<template>
  <ModalWarning
    :show="show"
    :title="title || $t('general.default_confirm_title')"
    :secondary-button="secondaryButton || { text: $t('general.cancel') }"
    :primary-button="primaryButton || { text: $t('forms.confirm') }"
    :size="size"
    :image-src="imageSrc"
    :hide-icon="hideIcon"
    @close="emit('close')"
    @secondary-click="emit('close')"
    @primary-click="confirm"
  >
    <template #introText>
      <slot>
        {{ $t("general.default_confirm_message") }}
      </slot>
    </template>
    <template #default> </template>
  </ModalWarning>
</template>

<script setup>
import ModalWarning from "./ModalWarning.vue"
defineProps({
  show: Boolean,
  title: String,
  primaryButton: Object,
  secondaryButton: Object,
  size: String,
  imageSrc: String,
  hideIcon: Boolean,
})
const emit = defineEmits(["close", "confirm"])

const confirm = () => {
  emit("confirm")
  emit("close")
}
</script>
